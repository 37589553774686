import Cookies from 'js-cookie';

export const setupCookies = () => {
  const $cookiePanel = $('#cookie-panel');
  const cookieOptions = { expires: 7 };

  $('#cookie-consents').on("click",() => {
    $cookiePanel.toggleClass('cookie-hide');

    setTimeout(() => {
      $cookiePanel.hide();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'event': 'cookie_panel_hide' });
    }, 400);

    // cookies are used by GTM
    Cookies.set('cookies_consent', true, cookieOptions);
    Cookies.set('cookies_analytic', $('input#analytic').is(':checked'), cookieOptions);
    Cookies.set('cookies_marketing', $('input#marketing').is(':checked'), cookieOptions);
  });

  if (Cookies.get('cookies_consent')) {
    $cookiePanel.hide();
  } else {
    $cookiePanel.show();
  }
};
