import { merge } from 'rxjs';
import { distinctUntilChanged, flatMap, switchMap } from 'rxjs/operators';
import { activeRoute$, routeLinkElement$ } from './router';
import { path$ } from './url';

export const setupMobileNavigation = () => {
    const $mobilenav = $('#mobilenav');
    const $hamburger = $('.hamburger');

    const mobileNavToggle = (opened?: boolean): void => {
        $mobilenav.toggleClass('nav-visible', opened);
        $hamburger.toggleClass('open', opened);
    };

    $mobilenav.find('a').click(() => mobileNavToggle(false));
    $hamburger.click(() => mobileNavToggle());

    routeLinkElement$.pipe(switchMap(links => merge(path$, activeRoute$).pipe(
        distinctUntilChanged(),
        flatMap(route => links.map(element => ({
            element,
            isActive: route.startsWith(element.getAttribute('href')),
        }))),
    ))).subscribe(({ element, isActive }) => {
        $([element, element.parentElement]).toggleClass('active', isActive);
    });
};
