import Modernizr from 'modernizr';

export const browserTests = [
  Modernizr.mq('only all'),
  Modernizr.canvas,
  Modernizr.cssanimations,
  Modernizr.svgasimg,
  Modernizr.inlinesvg,
  Modernizr.preserve3d,
  Modernizr.csstransforms,
  Modernizr.csstransforms3d,
  Modernizr.csstransitions,
];
