import $ from 'jquery';
import { bindCallback, combineLatest, fromEvent, Subject } from 'rxjs';
import { delay, map, startWith, switchMap } from 'rxjs/operators';

const scrollSubject = new Subject<number>();
const scrollEvent$ = fromEvent<Event>(document, 'scroll');

const animateScroll = bindCallback((y: number, callback: (y: number) => any) => {
    const $scroller = $(document.scrollingElement);
    const scrollTop = y + $scroller.scrollTop();
    $scroller.animate({ scrollTop }, () => callback(y));
});

const isAnimated = combineLatest(
    scrollSubject.asObservable(),
    scrollSubject.asObservable().pipe(
        switchMap(y => animateScroll(y)),
        delay(100),
    ),
).pipe(
    map(([start, end]) => start !== end),
    startWith(false),
);

export const scroll$ = combineLatest(scrollEvent$, isAnimated);

export const scrollToElement = (element: Element, offset = 90) => {
    return scrollSubject.next(element.getBoundingClientRect().top - offset);
};
