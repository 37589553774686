import 'animate.css/animate.css';
import 'attrchange';
import 'bootstrap';
import 'jquery.scrollbar';
import 'jquery.scrollbar/jquery.scrollbar.css';
import { browserTests } from './browserTest';
import './globalStyles';
import './scripts/accordion';
import { setupBlogPostsIndicator } from './scripts/blog';
import { browserFix } from './scripts/browser-bugfix';
import { conferencesSetup } from './scripts/conferences';
import { setupCookies } from './scripts/cookies';
import { setupMap } from './scripts/googlemaps';
import { setupMobileNavigation } from './scripts/mobilenav';
import { setupModal } from './scripts/modal';
import './scripts/routerSubscriptions';
import { setupSwipers } from './scripts/swiper';
import { setupYoutubeEmbed } from './scripts/youtubeEmbed';
import './styles/animate-overrides.less';
import { careersAnimations } from './scripts/sal';



if (browserTests.some(passed => passed === false)) {
  window.location.href = '/oldbrowser';
}

$(document).ready(() => {
  setupBlogPostsIndicator();
  browserFix();
  conferencesSetup();
  setupCookies();
  setupMap();
  setupMobileNavigation();
  setupModal();
  setupSwipers();
  setupYoutubeEmbed();
  careersAnimations();
});
