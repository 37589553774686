const initMap = () => {
  const { Map, Marker } = google.maps;
  const mapElement = document.getElementById('map');
  const myLatLng = { lat: 52.212570, lng: 20.959103 };

  const map = new Map(mapElement, {
    center: myLatLng,
    scrollwheel: false,
    zoom: 13,
    mapTypeControl: false,
    draggable: true,
  });

  const marker = new Marker({
    map: map,
    position: myLatLng,
    title: 'TouK',
  });
};

const apiKey = `AIzaSyAQe8PnXb-zFeqEcqVLuYdnRztabmPzMcA`;

export const setupMap = () => {
  window.initMap = initMap;
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
  document.body.appendChild(script)
};
