import $ from 'jquery';
import { BehaviorSubject } from 'rxjs';
import { delay, distinctUntilChanged, skipUntil } from 'rxjs/operators';
import { hashchange$, popstate$ } from './events';
import { getHash, setHash } from './url';

const visibleModalId = new BehaviorSubject<string>(null);

const getModal = (id: string) => $(document.getElementById(id)).filter('.urlModal');
const showModal = (id: string) => getModal(id).modal('show');
const hideModal = (id?: string) => getModal(id || visibleModalId.getValue()).modal('hide');

const checkForModalUrl = () => {
    const currentUrlHash = getHash();
    if (currentUrlHash) {
        showModal(currentUrlHash);
    } else {
        hideModal();
    }
};

visibleModalId.pipe(
    skipUntil(visibleModalId.pipe(delay(200))),
    distinctUntilChanged(),
).subscribe(setHash);

hashchange$.subscribe(checkForModalUrl);
popstate$.subscribe(checkForModalUrl);

export const setupModal = () => {
    $('.urlModal')
        .on('show.bs.modal', event => visibleModalId.next(event.target.id))
        .on('hidden.bs.modal', () => visibleModalId.next(null));

    checkForModalUrl();
};
