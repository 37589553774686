import { combineLatest, from, merge, NEVER, Observable } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    retryWhen,
    shareReplay,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import { clicksOn, domChange$, popstate$, viewChange$ } from './events';
import { byVisibility } from './filters';
import { cutTo } from './math';
import { byBoolean, byNumber } from './sort';
import { getPath } from './url';

const calcElementVisibility = (refPoint: number, rect: { top: number, bottom: number, height: number }, padding = 20) => {
    const topDistance = Math.abs(rect.top + padding - refPoint);
    const bottomDistance = Math.abs(rect.bottom - padding - refPoint);
    const highestVisiblePoint = cutTo(rect.top, 0, window.innerHeight);
    const lowestVisiblePoint = cutTo(rect.bottom, 0, window.innerHeight);
    return {
        distance: Math.min(topDistance, bottomDistance),
        visiblePart: (lowestVisiblePoint - highestVisiblePoint) / rect.height,
    };
};

const ROUTE_ELEMENT_ATTR_NAME = 'data-route';
const ROUTE_LINK_ATTR_NAME = 'data-route-link';

const routeElement$: Observable<HTMLElement[]> = domChange$.pipe(
    map(() => Array.from<HTMLElement>(document.querySelectorAll(`[${ROUTE_ELEMENT_ATTR_NAME}]`))),
    shareReplay(1),
);

export const routeLinkElement$: Observable<HTMLElement[]> = domChange$.pipe(
    map(() => Array.from<HTMLElement>(document.querySelectorAll(`[${ROUTE_LINK_ATTR_NAME}]`))),
    shareReplay(1),
);

type Group<K, V> = { key: K, values: V[] };

function groupSiblingsBy<E, K>(array: E[], getKey: (item: E) => K): Group<K, E>[] {
    return array.reduce(
        (groups: Group<K, E>[], item: E) => {
            const [lastGroup] = groups.slice(-1);
            const currentKey = getKey(item);
            if (lastGroup && lastGroup.key === currentKey) {
                lastGroup.values.push(item);
            } else {
                groups.push({ key: currentKey, values: [item] });
            }
            return groups;
        },
        [],
    );
};

function sumRects(elements: HTMLElement[]) {
    const [element] = elements;
    const rects = elements.map(element => element.getBoundingClientRect());
    const top = Math.min(...rects.map(r => r.top));
    const bottom = Math.max(...rects.map(r => r.bottom));
    const rect = { top, bottom, height: bottom - top };
    return { element, rect };
};

const sortByVisibility = (elements: HTMLElement[]) => {
    const displayed = elements.filter(byVisibility());
    const refPoint = window.innerHeight * 0.35;
    const grouped = groupSiblingsBy(displayed, element => element.getAttribute(ROUTE_ELEMENT_ATTR_NAME));
    const [first] = grouped
        .map(({ values }) => sumRects(values))
        .map(({ rect, ...props }) => ({ ...props, ...calcElementVisibility(refPoint, rect) }))
        .sort(byNumber('distance'))
        .map(({ visiblePart, ...props }) => ({ ...props, fullyVisible: visiblePart > 0.95 }))
        .sort(byBoolean('fullyVisible'));
    return first;
};

const getElementByRoute = (route: string) => routeElement$.pipe(
    take(1),
    switchMap(elements => from(elements).pipe(
        filter(element => element.getAttribute(ROUTE_ELEMENT_ATTR_NAME) === route),
        first(),
        catchError(() => NEVER),
    )),
);

const activeRouteElement$ = combineLatest(routeElement$, viewChange$).pipe(
    debounceTime(100),
    map(([elements]) => sortByVisibility(elements)),
    filter(Boolean),
    distinctUntilChanged(),
    shareReplay(1),
);

const initialRouteElement = getElementByRoute(getPath()).pipe(
    first(),
    catchError(() => NEVER),
    shareReplay(1),
);

const popstateRouteElement$ = popstate$.pipe(
    switchMap(() => getElementByRoute(getPath())),
    retryWhen(errors => errors),
);

const linkRouteElement$ = clicksOn(`a[${ROUTE_LINK_ATTR_NAME}]`).pipe(
    switchMap(({ event, target }) => getElementByRoute(target.getAttribute('href')).pipe(
        tap(() => event.preventDefault()),
    )),
);

export const activeRoute$ = activeRouteElement$.pipe(
    map(({ element }) => element.getAttribute(ROUTE_ELEMENT_ATTR_NAME)),
    distinctUntilChanged(),
    shareReplay(1),
);

export const interactedRouteElement$ = merge(initialRouteElement, linkRouteElement$, popstateRouteElement$);
