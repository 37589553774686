const animationSetup = () => {
  const $animatedLinks = $('a[data-toggle-type="animated"]');

  $animatedLinks.on('hide.bs.tab', ({ target }) => {
    const tab = $(target).attr('href');
    const $tab = $(tab);

    $tab.removeClass('animated slideInLeft');
    $tab.addClass('animated slideOutRight');
  });

  $animatedLinks.on('shown.bs.tab', ({ target, relatedTarget }) => {
    const nextTab = $(target).attr('href');
    const prevTab = $(relatedTarget).attr('href');

    $(prevTab).removeClass('animated slideOutRight');
    $(nextTab).addClass('animated slideInLeft');
  });
};

const scroll = direction => {
  const $scrollPanel = $('.year-panel.active .year-panel-scrollbar-inner');
  if ($scrollPanel) {
    const scrollLeft = `${direction}=${$scrollPanel.width()}`;
    $scrollPanel.animate({ scrollLeft });
  }
};

export const conferencesSetup = () => {
  animationSetup();
  $('#conferenceScrollLeft').click(() => scroll('-'));
  $('#conferenceScrollRight').click(() => scroll('+'));
  $('.scrollbar-inner').scrollbar();
};
