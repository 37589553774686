import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { viewChange$ } from './events';

export function getPath(): string {
    return location.pathname;
}

export function getHash(): string {
    return location.hash.substring(1);
}

export function setPath(path = '/'): void {
    if (path !== getPath()) {
        history.pushState(path, null, path);
    }
}

export function setHash(hash: string): void {
    if (hash !== getHash()) {
        if (hash) {
            location.hash = hash;
        } else {
            const path = getPath();
            history.pushState(path, null, path);
        }
    }
}

export const path$ = viewChange$.pipe(
    debounceTime(100),
    map(() => getPath()),
    distinctUntilChanged(),
    shareReplay(1),
);
