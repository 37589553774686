const createThumbnail = (id: string) => `
    <img src="https://i.ytimg.com/vi/${id}/hqdefault.jpg" alt="" />
    <div class="play"></div>
`;

const createIFrame = (id: string) => {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', `https://www.youtube.com/embed/${id}?autoplay=1`);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', '1');
    return iframe;
};

export const setupYoutubeEmbed = () => {
    document.querySelectorAll<HTMLElement>('.youtube-player').forEach(element => {
        const { id } = element.dataset;
        const wrapper = document.createElement('div');
        wrapper.innerHTML = createThumbnail(id);
        wrapper.onclick = () => element.replaceChild(createIFrame(id), wrapper);
        element.appendChild(wrapper);
    });
};
