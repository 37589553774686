import { Observable, Subscriber, TeardownLogic, Unsubscribable } from 'rxjs';

class InnerDisposable implements Unsubscribable {
    private isDisposed = false;

    constructor(private mutationObserver: MutationObserver) {}

    unsubscribe(): void {
        if (!this.isDisposed) {
            this.mutationObserver.disconnect();
            this.isDisposed = true;
        }
    }
}

class MutationObserverObservable extends Observable<MutationRecord[]> {
    constructor(
        private target: Node,
        private options: MutationObserverInit,
    ) {
        super();
    }

    static getMutationObserver(next) {
        return new MutationObserver(next);
    }

    _subscribe(subscriber: Subscriber<any>): TeardownLogic {
        const mutationObserver = MutationObserverObservable.getMutationObserver(e => subscriber.next(e));
        mutationObserver.observe(this.target, this.options);
        return new InnerDisposable(mutationObserver);
    }
}

export const fromMutationObserver = (
    target: Node = document.body || document.documentElement,
    options?: MutationObserverInit,
) => {
    if (!MutationObserver) { throw new TypeError('MutationObserver not implemented in your runtime.'); }
    return new MutationObserverObservable(target, { attributes: true, childList: true, subtree: true, ...options });
};
