import Swiper, { SwiperOptions } from 'swiper';
import 'swiper/dist/css/swiper.css';
import '../styles/swiper.styl';

const commonOptions: SwiperOptions = {
    direction: 'horizontal',
    speed: 1200,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
};

const swipers: { [selector: string]: SwiperOptions } = {
    '.swiper-team': {
        slidesPerColumn: 3,
        slidesPerView: 5, slidesPerGroup: 5,
        breakpoints: {
            1200: { slidesPerView: 4, slidesPerGroup: 4 },
            768: { slidesPerView: 3, slidesPerGroup: 3 },
        },
        loop: false,
        speed: 600,
    },
    '.swiper-clients': {
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
            stopOnLastSlide: false,
        },
        loop: true,
        speed: 600,
    },
    '.swiper-talks': {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        speed: 600,
    },
    '.swiper-quotes': {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        speed: 350,
        loop: true,
    },
};

export function setupSwipers() {
    return Object.entries(swipers)
        .map(([selector, options]) => new Swiper(selector, { ...commonOptions, ...options }));
}
