import { fromEvent, merge } from 'rxjs';
import { concatMap, debounceTime, delay, filter, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { fromMutationObserver } from './mutationObserver';
import { scroll$ } from './scroll';

const load$ = fromEvent<Event>(window, 'load');
const resize$ = fromEvent<Event>(window, 'resize');
const click$ = fromEvent<MouseEvent>(document, 'click');

const mutation$ = fromMutationObserver().pipe(
    debounceTime(100),
    startWith(null),
    shareReplay(1),
);

export const domChange$ = load$.pipe(
    switchMap(() => mutation$),
    shareReplay(1),
);

export const popstate$ = fromEvent<PopStateEvent>(window, 'popstate');
export const hashchange$ = fromEvent<PopStateEvent>(window, 'hashchange');

export function clicksOn(selector: string) {
    return click$.pipe(
        concatMap((event: Event) => event.composedPath().map((target: Element) => ({ target, event }))),
        filter(({ target }) => target.matches && target.matches(selector)),
    );
}

export const viewChange$ = merge(
    load$.pipe(startWith(null)),
    scroll$,
    resize$.pipe(delay(250)),
).pipe(
    shareReplay(1),
);
