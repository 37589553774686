import Cookies from 'js-cookie';

export const setupBlogPostsIndicator = () => {
  const feedUrl = 'https://touk.pl/blog/feed/json';
  const $blog = $('.blog-indicator');
  const $newPostsIndicator = $('#newPostsCount');

  $blog.on('click', ({ target }) => {
    const $target = $(target);
    const current = $target.data('current');

    $target.data('seen', current);
    Cookies.set('last_seen_blog_post', current);
  });

  $.get(feedUrl, ([latest]) => {
    $blog.data('current', latest.id);
    $blog.data('seen', Cookies.get('last_seen_blog_post'), { expires: 60 * 60 * 24 * 30 }); //30 days

    const seen = $blog.data('seen');
    const current = $blog.data('current');
    const hasNewPosts = (seen === undefined || current > seen);

    $newPostsIndicator.toggleClass('visible', hasNewPosts);
  });
};
