import { filter } from 'rxjs/operators';
import { expandAccordion } from './accordion';
import { bySelector, byVisibility } from './filters';
import { activeRoute$, interactedRouteElement$ } from './router';
import { scrollToElement } from './scroll';
import { setPath } from './url';

interactedRouteElement$.pipe(
    filter(byVisibility(false)),
    filter(bySelector('.urlAccordion')),
).subscribe(expandAccordion);

interactedRouteElement$.pipe(
    filter(byVisibility()),
).subscribe(scrollToElement);

activeRoute$.subscribe(setPath);
